import web3 from '../../assets/web3.png'
import web4 from '../../assets/web4.png'
import web2 from '../../assets/web2.png'
import web1 from '../../assets/web7.png'
import web6 from '../../assets/web6.png'
import web8 from '../../assets/web8.png'


export const data =[
    {id:1, img:<img src={web3} />, title: "EduLantern - Website",git:"GitHub", demo:"Live Demo", sitelink:"https://edulantern.netlify.app/"},
    {id:2, img:<img src={web8} />, title: "AcoNewsLive - Website",git:"GitHub", demo:"Live Demo", sitelink:"https://aco-news.netlify.app/"},
    {id:3, img:<img src={web1} />, title: "Doctor App - Website",git:"GitHub", demo:"Live Demo", sitelink:"https://frontend-doctor-app.vercel.app//"},
    {id:4, img:<img src={web6} />, title: "Dashboard MetStack",git:"GitHub", demo:"Live Demo", sitelink:"https://dashboard-metstack.netlify.app/"},
    {id:5, img:<img src={web4} />, title: "FlyLand - Website",git:"GitHub", demo:"Live Demo", sitelink:"https://flyland.netlify.app"},
    {id:6, img:<img src={web2} />, title: "CricketClub - Website",git:"GitHub", demo:"Live Demo", sitelink:"https://calicutcricketclub.pages.dev"},

]